import {API_BASE_URL} from "../constants/api";
import axios from "axios";
import {ACCESS_TOKEN} from "../constants/auth";

const SignatureService = {};

SignatureService.convertToXML = async function (fullName, docTemplate, docDetails, documentId) {
    let data = new FormData();
    data.append("fullName", fullName);
    data.append("docTemplate", docTemplate);
    data.append("docDetails", docDetails);
    data.append("documentId", documentId);
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/signature/to-xml`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

SignatureService.getSignatureById = async function (id) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/signature/get/${id}`,
        headers: {
            "Content-Type": "application/json"
        }
    });
}

SignatureService.convertApplicationsToFormXmls = async function (ids) {
    let data = new FormData();
    data.append("ids", ids);
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/signature/convert`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}


export default SignatureService;