import React, {useEffect, useState} from 'react';
import {Flex} from "antd";
import './sign_result.module.css';
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import MyText from "../../components/UI/Text/MyText";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import SignatureService from "../../services/SignatureService";
import XMLViewer from "react-xml-viewer";
import BlockLoading from "../../components/LoadingComponents/BlockLoading";

const SignResult = () => {

    const {signatureId} = useParams();
    const [signature, setSignature] = useState();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const columns = [
        {
            title: <IntlMessage id={'fullName'}/>,
            render: (_, record) => (
                <p>{record.fullName}</p>
            ),
        },
        {
            title: <IntlMessage id={'fullName'}/>,
            render: (_, record) => (
                <p>{record.who}</p>
            ),
        },
        {
            title: <IntlMessage id={'fullName'}/>,
            render: (_, record) => (
                <p>{record.date}</p>
            ),
        },
    ];

    const signers = [
        {
            fullName: "Dias Utebayev",
            who: "Me",
            date: "01-12-2024"
        }
    ]

    useEffect(() => {
        SignatureService.getSignatureById(signatureId)
            .then((r) => {
                setLoading(false);
                setSignature(r);
            })
            .catch(() => {
                setLoading(false);
            })
    }, [signatureId]);

    return (
        <Flex align={"center"} justify={"center"} style={{width: "100%"}}>
            <BlockLoading isLoading={loading}/>
            {!!signature && (
                <Flex style={{width: "100%", position: "relative", maxWidth: 1440, padding: "100px 20px 20px 20px"}} align={"center"}
                      justify={"center"} vertical gap={40}>
                    <MyText uppercase strong size={"large"}><IntlMessage id={'signResult'}/></MyText>
                    <Flex vertical gap={"small"}>
                        <MyText style={{alignSelf: "center"}} strong><IntlMessage id={'signInfo'}/></MyText>
                        <MyText><IntlMessage id={'signFIO'}/>{signature?.data?.fullName}</MyText>
                        {/*<MyText><IntlMessage id={'signIIN'}/>030316030316</MyText>*/}
                        <MyText><IntlMessage id={'signDetails'}/>{signature?.data?.docDetails}</MyText>
                        <MyText><IntlMessage id={'signDate'}/>{new Date(signature?.data?.signDate).toLocaleString()}</MyText>
                    </Flex>
                    <div style={{fontSize: '0.7rem', maxWidth: 1440, textWrap: "wrap"}}>
                        <XMLViewer collapsible={true} initialCollapsedDepth={1} xml={signature?.data?.signature}/>
                    </div>
                </Flex>
            )}
        </Flex>
    );
};

export default SignResult;