import {API_BASE_URL} from "../constants/api";
import axios from "axios";

const DocumentService = {};

DocumentService.getDocumentById = async function (id) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/document/get/${id}`,
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export default DocumentService;